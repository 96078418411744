<template>
  <div>
    <div class="authentication-page card mb-3 profile-card bt">
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0">Security Settings</h3>
      </div>
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
            
            <div class="grid-content mb-1" v-loading.fullscreen.lock="loading">
              <el-card>
                <h2>Mobile App Authentication (2FA)</h2>
                <p class="text-justify">Secure your account with Two-Factor Authentication (TOTP) through Google, Microsoft and One authentication apps. </p>
                <el-button class="setup-button" type="primary" @click="setup2FA(getAuthenticatedUser.mf2a)">
                  <span v-if="getAuthenticatedUser.mf2a">Disable</span> <span v-else>Setup</span> 2FA</el-button>
              </el-card>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="6">
            <div class="grid-content mb-1" v-loading.fullscreen.lock="loading">
              <el-card>
                <h2>OTP Validation </h2>
                <p class="text-justify">Secure your account with Two-Factor Authentication (OTP) through your registered mail / Mobile Number (Whatsapp) </p>
                <el-button class="setup-button" type="primary" @click="setupOTP()">
                  <span >Enable</span> </el-button>
              </el-card>        
            </div>

          </el-col>
         <!--  <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11"><div class="grid-content bg-purple"></div></el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"><div class="grid-content bg-purple-light"></div></el-col> -->
        </el-row>
      </div>

    
    <dialog-component
    :v-loading="popUploading"
    :visible="show2FACard"
    @before-close="close2FACard"
    :containerWidth="getIsMobile ? '100%' : '50vw'"
    :containerMaxHeight="'70vh'"
    :title="'Two-Factor Authentication (2FA)'">
    <br />
    <el-card>
      <div class="two-fa-container">
        <h3 class="heading">Configuring Google / Microsoft Authenticator</h3>
        <ol>
          <li class="list-item">Install Google Authenticator (iOS - Android) or Microsoft Authentication (iOS - Android).</li>
          <li class="list-item">In the authenticator app, select "+" icon. Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.</li>
        </ol>
        <div class="qr-code-container">
          <h3 style="text-align: left;" class="heading">Scan the QR</h3>
          <img :src="qrCode" alt="QR Code">
        </div>
        <h3 class="heading">Or Enter Code Into Your App</h3>
        <div>
          SecretKey: {{ secretKey }}
        </div><br>
        <el-form :model="form">
          <el-form-item>
            <h3 class="heading">Verify Code (One time password code)</h3>
            <el-input v-model="form.authCode" placeholder="Authentication Code"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <br />
    <span slot="footer" class="dialog-footer">
      <el-button @click="close2FACard">Close</el-button>
      <el-button type="primary" @click="verifyAndActivate">Verify & Activate</el-button>
    </span>
  </dialog-component>
</div>
  </template>
  
  
  <script>
//   import axios from 'axios';
    import { postAPICall } from '../../helpers/httpHelper';
    import QRCode from "qrcode";
    import { mapGetters } from "vuex";
    import Swal from "sweetalert2";
  
  export default {
    data() {
      return {
        show2FACard: false,
        form: {
          authCode: ''
        },
        qrCode: '',
        secretKey: '',
        loading:false,
        popUploading:false,
      };
    },
    methods: {
      async setupOTP(){
        Swal.fire({
            icon: "info",
            title: "We are working on it",
            showConfirmButton: true,
            timer: 5000
          });
      },
      async setup2FA(status) {
        try {
          this.loading=true;
          if(!status)
          {
          const response = await postAPICall('POST','/mf2a/generate', { enableMF2A: true });
          if (response.data) {
            // this.form.authCode = response.data.authCode; 
            this.secretKey= response.data.base32;
            await QRCode.toDataURL(response.data.otpauth_url).then( (data)=>{
                this.qrCode= data
            });
          }
          this.show2FACard = true;
          this.loading=false;
        }
        else
        {  
          const response = await postAPICall('POST','/mf2a/generate', { enableMF2A: false });
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "2FA Deactivated !",
            showConfirmButton: false,
            timer: 5000
          });
          await this.$store.dispatch("auth/switchWorkspace", {
              currentWorkspaceId: this.getActiveWorkspace.company_id,
            });
          
            this.loading=false;
        }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message || error.message || "Something went wrong",
            showConfirmButton: false,
            timer: 5000
          });
        }
      },
      async verifyAndActivate() {
        try {
          this.popUploading=true;
          const response = await postAPICall('POST','/mf2a/validateOTP', { token: this.form.authCode });
          if (response.otp_verified) {
            this.close2FACard();
            await this.$store.dispatch("auth/switchWorkspace", {
              currentWorkspaceId: this.getActiveWorkspace.company_id,
            });
            this.popUploading=false;
            // this.$message.success('2FA activated successfully!');
            Swal.fire({
            icon: "success",
            title: "2FA activated successfully!",
            showConfirmButton: false,
            timer: 5000
          });
          } else {
            this.popUploading=false;
            Swal.fire({
            icon: "error",
            title: "Invalid authentication code.",
            showConfirmButton: false,
            timer: 5000
          });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message || error.message || "Something went wrong",
            showConfirmButton: false,
            timer: 5000
          });
        }
      },
      close2FACard() {
        this.show2FACard = false;
      }
    },
    computed: {
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("auth", ["getErrorMessage","getOtpVerifiedStatus",
      "getAuthenticationStatus",
      "getAccountNotVerified",
      "getAccountNotVerifiedEmail",
      "getWorkspaceList",
      "getAuthenticatedUser",
      "getUserType",
      "getErrorCode",
      "getErrorData",
      "getResendVerificationEmail",
      "getMf2a","getActiveWorkspace"
    ])
  }
  };
  </script>
  
  
  <style scoped>
  .profile-card{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform : scale(1.0);
}
.authentication-page {
  font-family: 'Nunito Sans', sans-serif;
  min-height: 70vh;
  display: flex;
}
.grid-content{
      margin-left: 35px;
    width: 86%;
    margin-top: 50px;
}
.setup-button {
  background-color: #6366f1;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.setup-button:hover {
  background-color: #4f46e5;
}

.box-card {
  max-width: 600px;
  min-height: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
}

.two-fa-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
}

.heading {
  color: var(--primary-color);
  font-size: 18px;
  margin-bottom: 20px;
}

.heading::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -10px;
  left: 0;
}

.list-item {
  font-size: 17px;
}

.qr-code-container {
  text-align: center;
  margin: 20px 0;
  width: 100%;
}

.qr-code-container img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.card-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.text-justify{
  text-align: justify !important;
}
</style>

